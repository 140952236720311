body,
html {
  font-size: 11px;
}

// .entrance-container {
//   width: auto;
//   height: auto;
//   .container {
//     max-height: 100vh;
//   }
// }

.main-layout-wrapper {
  .sidebar-container {
    width: 150px;
    padding-left: 5px;
    padding-right: 5px;

    &.inactive {
      margin-left: -150px;
    }
  }
}

.welcome-container {
  .logo {
    width: 150px;
  }
}

.ending-container {
  .box-left,
  .box-right,
  .box-center {
    height: 80px;
    width: 96px;
  }
}

.ending-container-mobile {
  .box-left,
  .box-right,
  .box-center {
    width: 100px;
    height: 83px;
  }
}

.research-container,
.research-container-mobile {
  .impact-box {
    width: 100px;
    height: 100px;
  }
}

@include media-md {
  body,
  html {
    font-size: 13px;
  }

  .entrance-container {
    margin-left: 10%;
    margin-right: 10%;
    height: auto;
    width: auto;

    .container {
      max-height: 85vh;
    }
  }

  .main-layout-wrapper {
    .sidebar-container {
      width: 200px;
      padding-left: 15px;
      padding-right: 15px;

      &.inactive {
        margin-left: -200px;
      }
    }
  }
  .welcome-container {
    .logo {
      width: 250px;
    }
  }

  .research-container,
  .research-container-mobile {
    .impact-box {
      width: 125px;
      height: 125px;
    }
  }
}

@include media-lg {
  body,
  html {
    font-size: 16px;
  }

  .entrance-container {
    .container {
      max-width: 850px;
    }
  }

  .main-layout-wrapper {
    .sidebar-container {
      width: 250px;
      padding-left: 15px;
      padding-right: 15px;

      &.inactive {
        margin-left: -250px;
      }
    }
  }

  .welcome-container {
    .logo {
      width: 300px;
    }
  }

  .ending-container {
    .box-left,
    .box-right,
    .box-center {
      height: 91px;
      width: 110px;
    }
  }

  .ending-container-mobile {
    .box-left,
    .box-right,
    .box-center {
      width: 150px;
      height: 125px;
    }
  }

  .research-container,
  .research-container-mobile {
    .impact-box {
      width: 150px;
      height: 150px;
    }
  }
}

@include media-xl {
}
