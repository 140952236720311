.research-container,
.research-container-mobile {
  $borderRadius: 8px;

  .impact-box {
    box-shadow: 0px 2px 2px 1px rgba($color: black, $alpha: 0.2);
    cursor: pointer;
    background-color: rgba($color: #f7f7f7, $alpha: 0.85);
    color: #435563;

    font-family: "Roboto";
    font-weight: 500;
    font-size: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 15px;

    span {
      text-align: center;
    }

    &:hover {
      background-color: rgb(230, 230, 230);
    }

    &.top-left-corner {
      border-top-left-radius: $borderRadius;
    }
    &.top-right-corner {
      border-top-right-radius: $borderRadius;
    }
    &.bottom-left-corner {
      border-bottom-left-radius: $borderRadius;
    }
    &.bottom-right-corner {
      border-bottom-right-radius: $borderRadius;
    }

    &.active {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    }
  }

  .triangle-wrapper {
    top: 0;
    transition: all 0.3s;
    display: flex;
    display: -webkit-flex;
    justify-content: center;

    img {
      width: 25px;
    }
  }

  .body-container {
    $sidePadding: 53px;

    background-color: #87a1b4;
    // padding: 40px 38px;
    border-radius: 8px;
    box-shadow: -1px 2px 5px 1px rgba($color: #000000, $alpha: 0.2);

    display: flex;
    flex-direction: column;

    .top-section {
      padding: 35px $sidePadding 25px;
    }

    .mid-section {
      padding: 0px 0 0px 0px;
      overflow: hidden;
      margin-bottom: -2px;
      .row {
        .d-flex {
          padding-bottom: 20px;

          .tab {
            text-transform: uppercase;
            color: rgba($color: white, $alpha: 0.4);
            font-family: "Roboto";
            font-weight: 700;
            font-size: 1rem;
            padding-bottom: 3px;
            cursor: pointer;

            &:hover {
              color: #435563;
            }
          }
        }
        .d-flex.active {
          border-bottom: 2px solid #435563;

          .tab {
            color: #435563;
            font-weight: 700;
          }
        }

        div:nth-child(1) {
          padding-left: $sidePadding;
        }
        div:nth-child(2) {
          padding-right: $sidePadding;
        }
      }
    }
    .bottom-section {
      padding: 38px $sidePadding 43px;
      background-color: #f7f7f7;
    }

    .study-name {
      color: #435563;
      font-family: "Brown";
      font-weight: bold;
      font-size: 1.5rem;
    }

    .back-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 15px;
      cursor: pointer;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.2, 1.2);
      }
    }

    .author {
      font-family: "Roboto";
      font-size: 1.1rem;
      font-weight: 400;
      color: white;
    }

    .study-type {
      @extend .author;
      font-size: 1rem;
      position: relative;

      .border-bottom {
        position: absolute;
        border-bottom: 1px solid white;
        height: 1px;
        width: 200px;
        bottom: 0;
        left: 0;
      }
    }
    .subheader {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 1rem;
      color: #435563;
      text-decoration: underline;
    }

    .body {
      color: #435563;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 1rem;
    }
  }

  .quote-author {
    text-align: end;
    font-family: "Roboto";
    font-weight: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #4f4f50;
  }
}
