$Allports: #165f8e;
$ReefGold: #ac8a32;

@mixin media-sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin media-lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}
@mixin media-xl {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
@mixin media-xxl {
  @media screen and (min-width: 1600px) {
    @content;
  }
}

@font-face {
  font-family: "Brown";
  src: url("fonts/Brown-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Brown";
  font-weight: bold;
  src: url("fonts/Brown-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Brown";
  font-weight: bold;
  font-style: italic;
  src: url("fonts/Brown-Bold-Italic.otf") format("opentype");
}

@font-face {
  font-family: "Brown";
  font-weight: 200;
  src: url("fonts/Brown-Light.otf") format("opentype");
}

@font-face {
  font-family: "Brown";
  font-weight: 100;
  src: url("fonts/Brown-Thin.otf") format("opentype");
}
