.header {
  font-family: Brown;
  font-weight: bold;
  font-size: 2rem;
  color: #ac8a32;
  text-align: center;
  text-transform: uppercase;
}

.subheader {
  font-family: "Roboto";
  font-weight: normal;
  font-size: 1.1rem;
  color: #ac8a32;
  text-align: center;
}

.body-header {
  font-family: Brown;
  font-weight: bold;
  font-size: 1.5rem;
  color: #165f8e;
  margin-bottom: 10px;
  // border-bottom: 2px solid #e8e8e8;

  .header-underline {
    position: relative;

    height: 2px;
    width: 210px;

    background-color: #e8e8e8;
    @extend .unclickable;

    border-radius: 1px;
    margin-top: 10px;
  }
}

.body-paragraph {
  font-family: "Roboto";
  font-weight: normal;
  font-size: 1rem;
  color: #4f4f50;
}

.header-row {
  background-color: #f7f7f7;
  border-radius: 10px 10px 0 0;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
}

.header-section {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 45px 20px 28px 20px;
}

.body-row {
  display: block;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
}

.body-section {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 50px 30px 50px;
}

.rounded-corner {
  border-radius: 20px;
}

.aspect-ratio-16-9 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe.YTB-video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.quote-wrapper {
  width: 100%;
  background-color: #f7f7f7;
  border-left: 2px solid #165f8e;
  padding: 20px 18px;
  position: relative;
  display: flex;
  flex-direction: column;

  .quote {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1rem;
    color: #165f8e;
    margin-bottom: 29px;
  }

  .author {
    display: block;
    font-family: Roboto;
    font-style: italic;
    font-weight: bold;
    font-size: 0.9rem;
    color: #165f8e;
    padding: 0 0 0 9%;
    align-self: flex-end;
  }
}

.close-btn-wrapper {
  position: absolute;
  z-index: 10;
  transform: translate(50%, -50%);
  top: 0;
  right: 0;

  width: 50px;
  height: 50px;

  border-radius: 50%;
  background-color: rgba($color: #87a1b4, $alpha: 1);

  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;

  .cross {
    position: relative;
    width: 30%;
  }
  &:hover {
    background-color: rgba($color: #9ab9cf, $alpha: 1);
  }
}

.welcome-container {
  position: absolute;
  background-color: rgba($color: #ffffff, $alpha: 0.98);
  width: 100%;
  height: 100%;
  @extend .all-dir-0;
  color: #ac8a32;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  overflow: auto;
  .container-fluid,
  .container {
    min-height: -webkit-min-content;
    min-height: -moz-min-content;
    min-height: min-content;
  }

  .welcome-text {
    font-size: 1.5rem;
    color: $ReefGold;
    font-family: "Brown";
    font-size: 400;
    text-align: center;
  }

  .dropdown-label {
    font-family: "Brown";
    font-weight: 400;
    font-size: 1.4rem;
    color: #015783;
  }

  .persona-dropdown.dropdown {
    width: 100%;
    height: 75px;
  }

  .persona-dropdown-toggle.dropdown-toggle {
    width: inherit;
    height: inherit;

    font-family: "Brown";
    font-weight: 400;

    background-color: #e8e8e8;
    color: rgb(155, 155, 155);
    border-radius: 10px;

    padding: 0 20px;
    overflow: hidden;
    font-size: 1.3rem;
    @include media-sm {
      padding: 0 25px;
    }
    @include media-md {
      padding: 0 35px;
    }

    display: flex;
    display: -webkit-flex;
    justify-content: start;
    align-items: center;
    text-transform: none;
    &::after {
      justify-self: end;
      margin-left: auto !important;
      margin-right: 5px;
    }

    &:focus {
      outline: none !important;
      box-shadow: none； !important;
    }
  }

  .persona-dropdown-menu.dropdown-menu {
    background-color: #fbfbfb;
    width: 100%;
    box-shadow: 0 4px 3px 0 rgba($color: #000000, $alpha: 0.15);
    border-radius: 10px;
    min-width: 100%;
  }

  .persona-dropdown-option {
    font-family: "Brown";
    font-weight: 400;

    color: rgb(1, 87, 131);
    text-transform: none;

    padding: 3px 10px;

    overflow-x: hidden;

    font-size: 1.3rem;
    @include media-sm {
      padding: 7px 20px;
    }
    @include media-md {
      padding: 10px 30px;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

input.theme-input {
  height: 45px;
  width: 100%;
  text-align: center;
  background-color: #f7f7f7;
  color: #315f91;
  font-family: "Brown";
  font-size: 0.9rem;
  font-weight: normal;
  border-radius: 5px;
  border: none;
  outline: none;
}

.video-header {
  color: #315f91;
  font-size: 1rem;
  font-family: "Roboto";
  font-weight: 500;
}

.entrance-container {
  position: relative;
  pointer-events: initial;

  .container {
    max-height: 85vh;
  }

  .container,
  .container-fluid {
    width: 100vw;
    overflow-y: auto;
  }
}

.intro-container {
  @extend .entrance-container;
}

.legacy-container {
  @extend .entrance-container;

  // iframe.YTB-video {
  //   width: 100%;
  //   // height: 55vh;
  //   height: 100%;
  // }
}

.papi-container {
  @extend .legacy-container;
}

.ITP-container {
  @extend .legacy-container;
}

.research-container {
  @extend .legacy-container;
}

.programmes-container {
  @extend .entrance-container;
}

.backbone-container {
  @extend .entrance-container;
}

.connection-container {
  @extend .entrance-container;
}

.ending-container {
  @extend .entrance-container;
  @extend .ending-container-content-style;
}

.ending-container-content-style {
  .box-left,
  .box-right,
  .box-center {
    box-shadow: 0px 2px 2px 1px rgba($color: black, $alpha: 0.2);
    background-color: white;

    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    span {
      color: #435563;
      font-size: 0.75rem;
      font-family: "Roboto";
      font-weight: 500;
      text-align: center;
    }
  }
  .box-left {
    border-radius: 10px 0 0 10px;
  }
  .box-center {
    // border-radius: 5px 0 0 5px;
  }
  .box-right {
    border-radius: 0px 10px 10px 0;
  }

  .box-left:hover,
  .box-right:hover,
  .box-center:hover {
    background-color: rgb(230, 230, 230);
  }

  .mailing-list-section {
    width: 100%;
    // display: flex;
    // flex-direction: column;
    background: #f7f7f7;
    border-radius: 8px;

    padding: 35px 0;
  }

  label {
    transition: color 0.2s;
  }

  label.primary-label {
    color: #315f91;
    font-family: "Roboto";
    font-size: 0.75rem;
  }

  input {
    border: 1px solid #315f91;
    flex-grow: 1;
    padding: 5px 15px;
  }

  select {
    width: 100%;
    flex-grow: 1;
    text-align: center;
    background-color: white;
    color: #9b9b9b;
    font-family: "Roboto";
    font-size: 1rem;
    font-weight: normal;
    border-radius: 10px;
    border: 2px solid rgba($color: #9b9b9b, $alpha: 0.3);
    padding: 15px;

    outline: none;

    option {
      font-family: "Brown";
      color: #015783;
    }
  }

  label.grey-label {
    color: #9b9b9b;
    font-family: "Roboto";
    font-size: 0.75rem;
  }

  input.secondary-input {
    height: 45px;
    color: #015783;
    background-color: white;
    border-radius: 5px;
    border: 2px solid #e8e8e8;
    width: 100%;
    font-family: "Roboto";
    font-size: 0.9rem;
    font-weight: normal;
    outline: none;
    transition: all 0.3s;
    &:focus {
      outline: none;
      border: 2px solid #315f91;
    }
  }
}

.NIEExp-container {
  @extend .entrance-container;

  .placeholder {
    position: relative;
    width: 100%;
    opacity: 0;
    pointer-events: none;
  }

  #player {
    position: absolute;
    width: 100%;
    z-index: 5;
    top: 0;
    left: 0;
  }

  .player-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 1;
  }

  .hint-text {
    color: #315f91;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 1rem;
  }

  // .thumbnail-item {
  //   width: 64px;
  //   height: 64px;

  //   img {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }

  .menu-item-wrapper:focus {
    outline: none;
  }

  .fas {
    color: #ababab;
    @extend .clickable;
  }

  .fixed-scroll-menu-wrapper {
    position: relative;
    width: 100%;

    overflow: hidden;

    .cols-wrapper {
      width: 100%;
      position: relative;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      transition: transform 0.3s;
    }

    .item-col {
      min-width: 100% !important;
      width: 100%;
      display: flex;
      display: -webkit-flex;
      justify-content: space-around;
      align-items: center;

      .thumbnail-item {
        width: 10%;

        img {
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
  .arrow {
    position: absolute;
    width: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &__left {
      left: -15px;
    }
    &__right {
      right: -15px;
    }
  }
}

.GPL-container {
  @extend .legacy-container;

  .card-btn {
    background-color: #ffffff;
    // border-radius: 5px 0 0 5px;
    box-shadow: -1px 2px 5px 1px rgba($color: #000000, $alpha: 0.2);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0px 0px 0px;
    width: 22%;

    &.active {
      box-shadow: 0px 0px 10px 4px rgba($color: black, $alpha: 0.2);
    }

    img {
      width: 60%;
    }

    span {
      color: #435563;
      font-family: "Roboto";
      font-size: 0.7rem;
      font-weight: normal;
      min-height: 40px;
      padding: 0px 15px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .name {
      color: #435563;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 115%;
    }
  }

  .triangle-wrapper {
    // width: 20%;
    //transform: translate(0, -100%);
    top: 0;
    transition: all 0.3s;
    display: flex;
    display: -webkit-flex;
    justify-content: center;

    img {
      width: 25px;
    }
  }

  .body-container {
    background-color: #87a1b4;
    padding: 40px 38px;
    border-radius: 8px;
    box-shadow: -1px 2px 5px 1px rgba($color: #000000, $alpha: 0.2);

    .name {
      color: #435563;
      font-family: "Brown";
      font-weight: bold;
      font-size: 1.5rem;
    }
    .title {
      color: #ffffff;
      font-family: "Roboto";
      font-weight: normal;
      font-size: 1rem;
      padding-bottom: 10px;
    }

    .underline {
      width: 270px;
      max-width: 100%;
      height: 2px;
      background-color: #e8e8e8;
      position: absolute;
      left: 0;
      top: 100%;
      margin-left: 15px;
      border-radius: 1px;
    }

    .body-header {
      color: #ffffff;
      font-family: "Roboto";
      font-weight: bold;
      font-size: 0.9rem;

      border-bottom: none;
    }
    .body-paragraph {
      color: #ffffff;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 0.8rem;
    }

    .photo {
      position: absolute;

      right: 35px;
      top: 25px;
      width: 19%;
      z-index: 1;
    }

    .text-bubble {
      position: absolute;
      z-index: 2;
    }
  }
}

.professorship-container {
  @extend .entrance-container;
  .card-btn {
    position: relative;
    background-color: #ffffff;

    box-shadow: -1px 2px 5px 1px rgba($color: #000000, $alpha: 0.2);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px 0px 0px;
    width: 22%;

    img {
      width: 60%;
    }

    .text-wrapper {
      position: relative;

      padding: 10px;
      span {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      span.name {
        color: #435563;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 0.9rem;
      }

      span.title {
        margin-top: 2px;
        color: #435563;
        font-family: "Roboto";
        font-weight: normal;
        font-size: 0.65rem;
        line-height: 115%;
      }
    }
  }

  .triangle-wrapper {
    // width: 20%;
    //transform: translate(0, -100%);
    top: 0;
    transition: all 0.3s;
    display: flex;
    display: -webkit-flex;
    justify-content: center;

    img {
      width: 25px;
    }
  }

  .body-container {
    background-color: #87a1b4;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: -1px 2px 5px 1px rgba($color: #000000, $alpha: 0.2);

    .name {
      color: #435563;
      font-family: "Brown";
      font-weight: bold;
      font-size: 1.5rem;
    }
    .title {
      color: #ffffff;
      font-family: "Roboto";
      font-weight: normal;
      font-size: 1.15rem;
      padding-bottom: 10px;
    }

    .underline {
      width: 150px;

      @include media-sm {
        width: 200px;
      }

      @include media-md {
        width: 300px;
      }

      max-width: 80%;

      height: 2px;
      background-color: #e8e8e8;
      position: absolute;
      left: 0;
      top: 100%;
      margin-left: 15px;
      border-radius: 1px;
    }

    .body-paragraph {
      font-family: "Roboto";
      color: white;
      font-weight: normal;
      font-size: 1rem;
    }
  }
}

.professorship-container .card-btn.active,
.professorship-container-mobile .card-btn.active {
  box-shadow: 0px 0px 10px 4px rgba($color: black, $alpha: 0.2);
}

.empty-message {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(228, 0, 0);
  font-size: 0.8rem;
  width: 100%;
  text-align: center;
}

.theme.dropdown {
  flex-grow: 1;
  display: flex;
  display: -webkit-flex;
  &:hover {
    box-shadow: none !important;
  }
}

.theme.dropdown-toggle {
  text-transform: initial !important;
  // height: 100%;
  // min-height: 100%;
  width: 150px;
  font-size: 0.9rem;
  font-family: "Roboto";
  color: #9b9b9b;
  border: 2px solid #e8e8e8;
  border-radius: 5px;
  box-shadow: none !important;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;

  span {
    margin: auto auto;
    font-weight: normal;
  }

  &::after {
    justify-self: end;
    margin-left: auto !important;
    margin-right: 5px;
  }

  &:focus {
    outline: none !important;
    box-shadow: none； !important;
    border: 2px solid #315f91;
  }
}

.theme.dropdown-menu {
  background-color: #fbfbfb;
  width: 100%;
  box-shadow: 0 4px 3px 0 rgba($color: #000000, $alpha: 0.15);
  border-radius: 10px;
  min-width: 100%;
}

.theme.dropdown-item {
  color: #015783;
  font-family: "Brown";
  font-size: 1rem;

  &:focus {
    background-color: initial;
  }
}

.submission-result-wrapper {
  text-align: center;
  .main-message {
    font-family: "Brown";
    font-weight: bold;
    font-size: 1.6rem;
    color: #165f8e;
  }
  .statement {
    color: #4f4f50;
    font-family: "Roboto";
    font-size: 1rem;
  }
}
