.iframe-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: black, $alpha: 0.25);
  mix-blend-mode: overlay;
  pointer-events: none;
}

.iframe-wrapper {
  justify-self: stretch;
  height: 100%;
  width: 100%;
  position: relative;

  #showcase-iframe {
    height: 100%;
    width: 100%;
  }
}

.main-layout-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  .center-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    @extend .unclickable;

    .background {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #1b3142, $alpha: 0.8);
      @extend .unclickable;
    }
  }
}

.orientation-block-container {
  z-index: 99;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: white;
    font-size: 14px;
    font-family: "Brown";
    font-size: 300;
    text-align: center;
  }
}

.music-wrapper {
  position: absolute;
  z-index: 1;
  width: 25px;
  bottom: 26px;
  left: 105px;
  @extend .clickable;

  audio {
    opacity: 0;
    pointer-events: none;
    display: none;
  }
}

@import "./tour/sidebar";

@import "./overlays/overlays-hybrid";

@import "./overlays/overlays";

@import "./overlays/overlays-mobile";

@import "./tour//sidebar-mobile";
