@import "~bootstrap/scss/bootstrap";

@import "~mdb-ui-kit/css/mdb.min.css";

@import "config";

@import "custom";

@import "tour";

@import "responsive";

.theme-btn-auto {
  color: white;
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  padding: 15px 30px;
  background-color: #116685;
  border-radius: 6px;
  text-transform: uppercase;
  outline: none;
  border: none;
  letter-spacing: 1px;
  word-spacing: 3px;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #1683ab;
  }
}

.theme-btn {
  @extend .theme-btn-auto;
  padding: 10px 40px;
}

.theme-link {
  color: inherit;
  // transition: all 0.3s;
  border-bottom: 1px solid #165f8e;

  &:hover {
    text-decoration: none;
    color: #165f8e;
  }
}
