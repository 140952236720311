.entrance-container-mobile {
  width: 100%;
  height: 100%;
  pointer-events: initial;
  .header-row {
    background-color: #f7f7f7;

    display: flex;
    justify-content: center;

    border-radius: 0;

    border-bottom: 2px solid #e8e8e8;
  }

  .body-row {
    border-radius: 0;
    background-color: rgba($color: white, $alpha: 0.95);
    min-height: 100vh;
  }
  .header-section {
    padding-top: 27px;
    padding-bottom: 20px;
  }
  .container-fluid,
  .container {
    height: 100%;
    // display: flex;
    // flex-direction: column;
    overflow-y: auto;
  }

  .body-section {
    padding-top: 40px;
  }
}

.intro-container-mobile {
  @extend .entrance-container-mobile;
}

.legacy-container-mobile {
  @extend .entrance-container-mobile;

  .video-header {
    color: #315f91;
    font-size: 1rem;
    font-family: "Roboto";
    font-weight: 500;
  }

  // iframe.YTB-video {
  //   width: 100%;
  //   height: 250px;
  //   min-height: 60vh;
  // }
}

.papi-container-mobile {
  @extend .legacy-container-mobile;
}

.programmes-container-mobile {
  @extend .entrance-container-mobile;
}

.backbone-container-mobile {
  @extend .entrance-container-mobile;
}

.ITP-container-mobile {
  @extend .legacy-container-mobile;
}

.GPL-container-mobile {
  @extend .legacy-container-mobile;

  .card-btn {
    position: relative;
    background-color: #ffffff;
    // border-radius: 5px 0 0 5px;
    box-shadow: -1px 2px 5px 1px rgba($color: #000000, $alpha: 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0px 0px 0px;
    width: 22%;

    &.active {
      box-shadow: 0px 0px 10px 4px rgba($color: black, $alpha: 0.2);
    }

    img {
      width: 60%;
    }
    span {
      color: #435563;
      font-family: "Roboto";
      font-size: 0.7rem;
      font-weight: normal;
      min-height: 40px;
      padding: 0px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .name {
      color: #435563;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 115%;
    }
  }

  .triangle-wrapper {
    // width: 20%;
    //transform: translate(0, -100%);
    top: 0;
    transition: all 0.3s;
    display: flex;
    justify-content: center;

    img {
      width: 25px;
    }
  }
  .body-container {
    background-color: #87a1b4;
    padding: 40px 38px;

    box-shadow: -1px 2px 5px 1px rgba($color: #000000, $alpha: 0.2);
    max-width: 100vw;
    width: 100vw;

    .name {
      color: #435563;
      font-family: "Brown";
      font-weight: bold;
      font-size: 1.5rem;
    }
    .title {
      color: #ffffff;
      font-family: "Roboto";
      font-weight: normal;
      font-size: 1.1rem;
      padding-bottom: 10px;
    }

    .underline {
      width: 100%;
      max-width: 300px;
      height: 2px;
      background-color: #e8e8e8;
      position: absolute;
      left: 0;
      top: 100%;
      margin-left: 15px;
      border-radius: 1px;
    }

    .body-header {
      color: #ffffff;
      font-family: "Roboto";
      font-weight: bold;
      font-size: 1rem;

      border-bottom: none;
    }
    .body-paragraph {
      color: #ffffff;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 0.9rem;
    }

    .photo {
      position: absolute;

      right: 35px;
      top: 25px;
      width: 19%;
      z-index: 1;
    }

    .text-bubble {
      position: absolute;
      z-index: 2;
    }
  }
}
.professorship-container-mobile {
  @extend .GPL-container-mobile;

  .card-btn {
    justify-content: start;
  }

  .text-wrapper {
    position: relative;

    padding: 10px 10px 10px 10px;

    span {
      // // min-height: 0;
      // display: flex;
      // display: -webkit-flex;
      // align-items: center;
      // justify-content: center;
      text-align: center;
      padding: 0;
      min-height: 0;
    }
    span.name {
      color: #435563;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 0.9rem;
    }

    span.title {
      margin-top: 5px;
      color: #435563;
      font-family: "Roboto";
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 115%;
    }
  }
}

.research-container-mobile {
  @extend .legacy-container-mobile;
}

.connection-container-mobile {
  @extend .entrance-container-mobile;
}
.ending-container-mobile {
  @extend .ending-container-content-style;
  @extend .entrance-container-mobile;

  // .box-left,
  // .box-right,
  // .box-center {
  //   box-shadow: 0px 2px 2px 1px rgba($color: black, $alpha: 0.2);
  //   background-color: white;

  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 10px;

  //   span {
  //     color: #435563;
  //     font-size: 0.7rem;
  //     font-family: "Roboto";
  //     font-weight: 500;
  //     text-align: center;
  //   }
  // }

  // .box-left {
  //   border-radius: 10px 0 0 10px;
  // }
  // .box-center {
  //   // border-radius: 5px 0 0 5px;
  // }
  // .box-right {
  //   border-radius: 0px 10px 10px 0;
  // }

  // label.primary-label {
  //   color: #315f91;
  //   font-family: "Roboto";
  //   font-size: 0.75rem;
  // }

  // input {
  //   border: 1.5px solid #315f91;
  //   flex-grow: 1;
  //   padding: 5px 15px;
  // }

  // select {
  //   width: 100%;
  //   flex-grow: 1;
  //   text-align: center;
  //   background-color: white;
  //   color: #9b9b9b;
  //   font-family: "Roboto";
  //   font-size: 1rem;
  //   font-weight: normal;
  //   border-radius: 10px;
  //   border: 2px solid rgba($color: #9b9b9b, $alpha: 0.3);
  //   padding: 15px;

  //   outline: none;

  //   option {
  //     font-family: "Brown";
  //     color: #015783;
  //   }
  // }

  // label.grey-label {
  //   color: #9b9b9b;
  //   font-family: "Roboto";
  //   font-size: 0.75rem;
  // }

  // input.secondary-input {
  //   height: 45px;
  //   color: #015783;
  //   background-color: white;
  //   border-radius: 5px;
  //   border: 2px solid #e8e8e8;
  //   width: 100%;
  //   font-family: "Roboto";
  //   font-size: 0.9rem;
  //   font-weight: normal;
  //   outline: none;

  //   &:focus {
  //     outline: none;
  //   }
  // }
}

.NIEExp-container-mobile {
  @extend .entrance-container-mobile;

  .video-wrapper {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      width: 100%;
    }
  }

  .hint-text {
    color: #315f91;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 1rem;
  }

  .menu-item-wrapper:focus {
    outline: none;
  }

  .fas {
    color: #ababab;
    @extend .clickable;
  }

  .slider-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100%;

    .slider {
      position: relative;
      transition: all 0.3s;
      width: 100%;
      // overflow: hidden;
      height: 100%;
      padding: 0;
      margin: 0;
      flex-wrap: nowrap;
    }
  }

  .slide {
  }

  .thumbnail-wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }
  .pagination-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;

    .dot {
      width: 8px;
      height: 8px;
      background-color: #8c8c8c;
      border-radius: 50%;
      margin-left: 9px;
      margin-right: 9px;
      @extend .clickable;

      &.active {
        background-color: #116685;
      }
    }
  }
}

.close-btn-mobile {
  position: absolute;
  top: 20px;
  right: 25px;
  width: 20px;
  height: 20px;
  transition: all 0.3s;
  z-index: 10;
  &:hover {
    transform: scale(1.2, 1.2);
  }
}
