.sidebar-container-mobile {
  height: 100%;
  transition: all 0.3s;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  // width: 330px;
  width: 45%;

  &.active {
    // transform: translateX(0);
    margin-left: 0%;
  }

  &.inactive {
    // transform: translateX(-100%);

    // margin-left: -330px;
    margin-left: -45%;
  }

  i.icon {
    color: #87a1b4;
  }

  .sidebar-text {
    font-family: "Brown";
    font-weight: 400;
    color: #898989;
    font-size: 1rem;
  }

  .icon-text {
    font-family: "Brown";
    font-weight: 400;
    color: #898989;
    font-size: 1rem;
  }

  .right-border {
    border-right: 2px solid #bdbdbd;
  }

  .bottom-logo {
    width: 100%;
  }

  .icon-col.active {
    .icon,
    .icon-text {
      color: #165f8e;
    }
  }
}

// .collapse-btn-wrapper {
//   position: relative;
//   @extend .clickable;
//   width: 30px;
//   height: 24px;

//   .bar {
//     position: absolute;
//     width: 30px;
//     height: 4px;
//     background-color: white;
//     transform: translate(-50%, -50%);
//     border-radius: 3px;
//     transition: transform 1s, opacity 0.2s, background-color 0.3s;

//     &:nth-child(1) {
//       top: 0px;
//     }
//     &:nth-child(2) {
//       top: 10px;
//     }
//     &:nth-child(3) {
//       top: 20px;
//     }
//   }

//   &.active {
//     .bar {
//       &:nth-child(1) {
//         transform: rotate(405deg);
//         top: 10px;
//       }
//       &:nth-child(2) {
//         opacity: 0;
//       }
//       &:nth-child(3) {
//         top: 10px;
//         transform: rotate(-405deg);
//       }
//     }
//   }
// }
@import "~hamburgers/_sass/hamburgers/hamburgers";

.collapse-btn-wrapper.hamburger {
  z-index: 20;

  .hamburger-inner.white {
    background-color: #ffffff;
    &::before,
    &::after {
      background-color: #ffffff;
    }
  }

  .hamburger-inner.blue {
    background-color: #116685;
    &::before,
    &::after {
      background-color: #116685;
    }
  }
}

.contact-container-mobile {
  @extend .entrance-container-mobile;
}
