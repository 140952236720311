.justify-self-start {
  justify-self: flex-start;
}
.justify-self-center {
  justify-self: center;
}
.justify-self-end {
  justify-self: flex-end;
}

.clickable {
  cursor: pointer;
  pointer-events: initial;
}

.unclickable {
  pointer-events: none;
}

.all-dir-0 {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.pre-line {
  white-space: pre-line;
}

a.unstyled-link {
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.invisible {
  opacity: 0;
}

.round-corners-left {
  border-radius: 5px 0 0 5px;
}

.round-corners-right {
  border-radius: 0px 5px 5px 0px;
}

.neutralize-row-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.font-weight-500 {
  font-weight: 500;
}

.text-justified {
  text-align: justify;
  text-justify: inter-cluster;
}

::-webkit-scrollbar {
  display: block;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 0;
  // margin: 100px 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #165f8e;
  // max-height: 50px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1d7ab8;
}

::-webkit-scrollbar-track-piece:start {
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
}
