.sidebar-container {
  height: 100%;
  transition: all 0.3s;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;

  i.icon {
    color: #87a1b4;
  }

  .sidebar-text {
    font-family: "Brown";
    font-weight: 400;
    color: #898989;
    font-size: 0.9rem;
  }

  .icon-text {
    font-family: "Brown";
    font-weight: 400;
    color: #898989;
    font-size: 0.75rem;
  }

  .right-border {
    border-right: 2px solid #bdbdbd;
  }

  .bottom-logo {
    width: 100%;
  }

  .icon-col:hover,
  .icon-col.active {
    .icon,
    .icon-text {
      color: #165f8e;
    }
  }
}

.map-container {
  position: absolute;
  background-color: rgba($color: #325c7b, $alpha: 0.95);
  width: 100%;
  height: 100%;
  @extend .all-dir-0;
  color: white;
  z-index: 15;

  .container,
  .container-fluid {
    min-height: 375px;
  }

  .map-header {
    font-family: "Brown";
    font-weight: normal;
    color: white;
  }
  .map-footer {
    font-family: "Brown";
    font-weight: normal;
    color: #d9c48d;
    font-size: 0.8rem;
    letter-spacing: 2px;
  }

  .map-wrapper {
    img {
      @extend .clickable;
      position: relative;
      width: 100%;
    }

    .entry-point-wrapper {
      position: absolute;
      width: 6%;

      .hotspot {
        position: relative;
        width: 100%;
        transition: transform 0.3s;

        &.glow {
          border-radius: 50%;
          // box-shadow: 0 0 15px 6px white;
          animation: hotspotGlow 2s infinite alternate ease-in-out;
        }

        &:hover {
          transform: scale(1.2, 1.2);
        }
      }

      .index {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        @extend .unclickable;
      }

      .entry-name-wrapper {
        position: absolute;
        left: 50%;
        top: 110%;
        transform: translateX(-50%);

        .entry-name {
          display: inline-block;

          white-space: nowrap;
          font-size: 0.8rem;
          text-align: center;

          @extend .unclickable;
        }
      }
    }

    .intro {
      left: 46%;
      bottom: -2%;

      .entry-name-wrapper {
        top: 90%;
      }
    }
    .about {
      left: 15.5%;
      bottom: 21%;

      .entry-name-wrapper {
        top: 50%;
        transform: translateY(-50%);
        left: 125%;
      }
    }

    .program {
      top: 23%;
      left: 33%;

      .entry-name-wrapper {
        top: 135%;
      }
    }

    .research {
      right: 16%;
      top: 35%;
      .entry-name-wrapper {
        top: 120%;
      }
    }

    .professor {
      right: 14%;
      bottom: 15.5%;
    }
  }
}

.contact-container {
  @extend .entrance-container;
}

// .icon-col:hover,
// .icon-col.active {
//   .icon,
//   .icon-text {
//     color: #165f8e;
//   }
// }

// .icon.active,
// .icon-text.active {
//   color: #165f8e;
// }

.social-media-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // z-index: 10;

  .media-icon {
    width: 3vw;
    min-width: 30px;

    transition: transform 0.3s;
    &:hover {
      transform: scale(1.2, 1.2);
    }
  }
}

@keyframes hotspotGlow {
  0% {
    box-shadow: 0 0 15px 6px white;
  }
  100% {
    box-shadow: 0 0 20px 15px white;
  }
}

@-webkit-keyframes hotspotGlow {
  0% {
    box-shadow: 0 0 15px 6px white;
  }
  100% {
    box-shadow: 0 0 15px 15px white;
  }
}
